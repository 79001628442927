.Navbar{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 100%;

}

.Navbar div img{
    width: 100%;
    height: auto;
}
