.main-heading{
    font-size: var(--primary-size);
    font-weight: var(--primary-font);
    font-family: var(--primary-style);
}
.descrip{
    color: gray !important;
}
.secondary-heading{
    font-weight: var(--primary-font) !important;
    font-family: var(--primary-style);
}

@media screen and (max-width:500px) {
    .main-heading{
        font-size: 3rem!important;
    }
}