.js-scroll {
    opacity: 0;
    transition: opacity 20ms;
}

.js-scroll.scrolled {
    opacity: 1;
}

.scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;

    /* transform: translateX(0); */
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-400px);
        transform: translateX(-400px);
        opacity: 0;

    }

    /* 50% {
        -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
            opacity: 1;
        
    } */

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    /* 200% {
        -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
            opacity: 0;
    }
    300% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
    } */

}

.scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.my-services-row {
    margin-left: 5px;
    /* margin: var(--x) !important; */
}

.my-services-content {
    width: 100%;
    padding: 0 4px;
}

@media (min-width: 500px) {
    .my-services-content {
        padding: 0 8px;
    }
}

.my-services-content {
    padding-top: 4px;
}

@media (min-width: 500px) {
    .my-services-content {
        padding-top: 8px;
    }
}

.my-services {
    height: 100%;
}

/* main {
    display: block;
} */

.my-main {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

.my-layout {
    height: 100%;
    opacity: 0;
}

.scroll-content {
    display: flow-root;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.my-view .scroll-content {
    transform: translate3d(0px, -5100px, 0px) !important;
    height: 100%;
    will-change: transform;
}

:focus {
    outline: none !important;
}

.my-view {
    height: 100%;
}

[data-scrollbar] {
    display: block;
    position: relative;
}

.my-services-row {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
    margin-top: 4px;

}

.snipcss0-3-4-5 {
    text-align: center;
    color: #1B1B1F;
    font-size: 4rem;
    font-weight: 900;
}

@media (min-width: 500px) {
    .my-services-row {
        margin-top: 8px;
    }
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:selection {
    color: black;
    background: #FFF480;
}

.my-services-row__left {
    display: flex;
    display: -ms-flexbox;
    display: -moz-box;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;

}

.my-services-row__right {
    display: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    z-index: 2;

}

@media (min-width: 1024px) {
    .my-services-row__right {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.my-services-row__left--card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    border-radius: 48px;
    padding: 38px;
}

@media (min-width: 768px) {
    .my-services-row__left--card {
        -webkit-border-radius: 54px;
        -moz-border-radius: 54px;
        border-radius: 54px;
        padding: 44px;
    }
}

@media (min-width: 1200px) {
    .my-services-row__left--card {
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        border-radius: 70px;
    }
}

@media (min-width: 1400px) {
    .my-services-row__left--card {
        -webkit-border-radius: 75px;
        -moz-border-radius: 75px;
        border-radius: 75px;
        padding: 60px;
    }
}

.my-services-row__left--card {
    position: relative;
    background: #1B1B1F;
    color: #A5A1FF;
    font-family: var(--primary-style);
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    border-radius: 48px;
}

@media (min-width: 768px) {
    .my-services-row__left--card {
        -webkit-border-radius: 54px;
        -moz-border-radius: 54px;
        border-radius: 54px;
    }
}

@media (min-width: 1400px) {
    .my-services-row__left--card {
        -webkit-border-radius: 75px;
        -moz-border-radius: 75px;
        border-radius: 75px;
    }
}

.my-services-row__right--card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    border-radius: 48px;
    padding: 38px;
    /* width: 800px !important; */
}

@media only screen and (max-width: 500px) {
    .my-services-row__right--card {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 100px;
        padding: 0px;
        width: 0px !important;
        visibility: hiddens;
    }
}

@media only screen and (min-width: 768px) {
    .my-services-row__right--card {
        -webkit-border-radius: 54px;
        -moz-border-radius: 54px;
        border-radius: 54px;
        padding: 44px;
        /* width: 400px !important; */
    }
}

@media only screen and (min-width: 980px) {
    .my-services-row__right--card {
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        border-radius: 60px;
        /* width: 600px !important; */
    }
}

@media only screen and (min-width: 1200px) {
    .my-services-row__right--card {
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        border-radius: 70px;
        /* width: 800px !important; */
    }
}


.my-services-row__right--card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #A5A1FF;
    color: #1B1B1F;
}

.my-services-row__icon {
    /* display: none; */
    /* -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center; */
    /* align-items: center; */
    margin-right: 40px;
    align-items: center;
    height: 27vh;
}

.my-services-row__icon img {
    border-radius: 1.1rem;
}

@media (min-width: 350px) {
    .my-services-row__icon {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;

    }
}

@media (min-width: 768px) {
    .my-services-row__icon {
        margin-right: 46px;
    }
}

@media (min-width: 1024px) {
    .my-services-row__icon {
        margin-right: 50px;
    }
}

@media (min-width: 1400px) {
    .my-services-row__icon {
        margin-right: 56px;
    }
}

.my-services-row__text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 2;
    color: #aba7ff;
}

.my-services-row__left--card .card-extension {
    position: absolute;
    display: none;
    right: -100px;
    top: 0;
    width: 200px;
    height: 100%;
    background: #1B1B1F;
    z-index: 1;
}

@media (min-width: 1024px) {
    .my-services-row__left--card .card-extension {
        display: block;
    }
}

.my-services-row__text--right {
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 600;
}

@media (min-width: 768px) {
    .my-services-row__text--right {
        font-size: 17px;
    }
}

@media (min-width: 1024px) {
    .my-services-row__text--right {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

@media (min-width: 1400px) {
    .my-services-row__text--right {
        font-size: 19px;
        margin-bottom: 24px;
    }
}

a {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

svg {
    vertical-align: middle;
}

.my-services-row__icon svg {
    width: 24px;
    height: 24px;
}

@media (min-width: 768px) {
    .my-services-row__icon svg {
        width: 28px;
        height: 28px;
    }
}

@media (min-width: 1024px) {
    .my-services-row__icon svg {
        width: 31px;
        height: 31px;
    }
}

@media (min-width: 1400px) {
    .my-services-row__icon svg {
        width: 34px;
        height: 34px;
    }
}

.my-services-row__text--top {
    font-size: 16px;
    font-weight: 625;
    letter-spacing: 0;
    line-height: 100%;
    margin: 0 0 24px 0;
    color: #aba7ff;
    background: #1B1B1F;
    font-weight: 700;
}

@media (min-width: 768px) {
    .my-services-row__text--top {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        margin: 0 0 28px 0;
    }
}

@media (min-width: 1024px) {
    .my-services-row__text--top {
        font-size: 19px;
        margin: 0 0 36px 0;
    }
}

@media (min-width: 1400px) {
    .my-services-row__text--top {
        font-size: 20px;
        margin: 0 0 48px 0;
    }
}

.my-services-row__text--bottom {
    font-size: 16px;
    letter-spacing: 0.015em;
    font-weight: 425;
    color: #A5A1FF;
    background: #1B1B1F;
}

@media (min-width: 768px) {
    .my-services-row__text--bottom {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    .my-services-row__text--bottom {
        font-size: 19px;
        max-width: 95%;
    }
}

@media (min-width: 1400px) {
    .my-services-row__text--bottom {
        font-size: 20px;
    }
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-4SCKH.style-4SCKH {
    transform: translate(23.8901%, 0%) translate3d(0px, 0px, 0px);
}

#style-plcYf.style-plcYf {
    opacity: 1;
    visibility: inherit;
}

#style-onnG5.style-onnG5 {
    transform: translate(-23.8901%, 0%) translate3d(0px, 0px, 0px);
}



@media screen and (max-width: 768px) {
    .my-services-row__text--top {
        font-size: 1.5rem;
        font-weight: 625;
        letter-spacing: 0.1rem;
        /* line-height: 88%; */
        margin: 0 0 24px 0;
        color: #aba7ff;
        background: #1B1B1F;
    }

    .my-services-row__text--bottom {
        font-size: smaller;
        letter-spacing: 0.015em;
        font-weight: 425;
        color: #A5A1FF;
        background: #1B1B1F;
    }

    .my-services-row__right--card {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 100px;
        padding: 0px;
        width: 0px !important;
        visibility: hiddens;
    }

    .snipcss0-3-4-5 {
        text-align: center;
        color: #1B1B1F;
        font-size: 0rem;
        font-weight: 900;
    }

}

@media screen and (max-width: 500px) {


    .my-services-row__text--bottom {
        font-size: smaller;
        letter-spacing: 0.015em;
        font-weight: 425;
        color: #A5A1FF;
        background: #1B1B1F;
    }

    .my-services-row__left--card {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 48px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .snipcss0-3-4-5 {
        text-align: center;
        color: #1B1B1F;
        font-size: 0rem;
        font-weight: 900;
    }

    .my-services-row__icon {
        /* display: none; */
        align-items: center;
        margin-right: 6px;
    }

    .my-services-row__text {
        margin-left: 2rem;

    }



}