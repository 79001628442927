.heading {
    /* margin-top: 0.5rem; */
    margin: 0.5rem;
    padding: 3rem 4rem;
    border-radius: 4rem !important;
    width: 100%;

}

.heading p {

    font-weight: var(--primary-font);
    letter-spacing: 0.1rem;
    font-size: 1rem;
    text-align: left !important;
}

.heading h1 {
    margin-top: 3rem;
    color: black;
    font-size: var(--primary-size);
    text-align: left;
    width: 100%;
}

@media (max-width:800px) {
    .heading {
        padding: 3rem 1.5rem !important;
    }

    .heading h1 {
        font-size: 3rem;
        letter-spacing: 0.1rem;
    }


}

@media screen and (max-width:500px) {
    .heading {
        padding: 4rem 2rem !important;
        margin: 3px;
    }

    .heading h1 {
        font-size: 1.5rem;
        line-height: 33px;
    }


}