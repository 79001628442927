.aboutSection {
    -ms-overflow-style: none;
    width: 99%;
    margin: auto;
}

:root {
    --marquee-move: 50px;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


.my-who-content {
    width: 100%;
    padding: 0 4px;
}

@media (min-width: 500px) {
    .my-who-content {
        padding: 0 8px;
    }
}

.my-who-content {
    padding-top: 4px;
}

@media (min-width: 500px) {
    .my-who-content {
        padding-top: 8px;
    }
}

.my-who {
    width: 100%;
    height: 100%;
}


.my-main {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

.my-layout {
    height: 100%;
    opacity: 0;
}

.scroll-content {
    display: flow-root;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.my-view .scroll-content {
    height: 100%;
    will-change: transform;
}

.my-view {
    height: 100%;
}

[data-scrollbar] {
    display: block;
    position: relative;
}


.my-who-card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #1F1A1C;
    overflow: hidden;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    border-radius: 48px;
    overflow-x: hidden !important;
    /* overflow-y: hidden!important; */
}

@media (min-width: 768px) {
    .my-who-card {
        -webkit-border-radius: 54px;
        -moz-border-radius: 54px;
        border-radius: 54px;

    }
}

@media (min-width: 1200px) {
    .my-who-card {
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        border-radius: 70px;
    }
}

@media (min-width: 1400px) {
    .my-who-card {
        -webkit-border-radius: 75px;
        -moz-border-radius: 75px;
        border-radius: 75px;
    }
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:selection {
    color: black;
    background: #FFF480;
}

.my-who-card .my-text-animation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    white-space: nowrap;
    width: 100%;
    will-change: transform;
    -webkit-transition: opacity 325ms ease, -webkit-transform 400ms ease;
    transition: opacity 325ms ease, -webkit-transform 400ms ease;
    -o-transition: opacity 325ms ease, -o-transform 400ms ease;
    -moz-transition: opacity 325ms ease, transform 400ms ease, -moz-transform 400ms ease;
    transition: opacity 325ms ease, transform 400ms ease;
    transition: opacity 325ms ease, transform 400ms ease, -webkit-transform 400ms ease, -moz-transform 400ms ease, -o-transform 400ms ease;
    z-index: 2;
    pointer-events: none;
}

.my-who-card__text {
    width: 100%;
    font-size: 18px;
    font-family: var(--primary-style);
    line-height: 154%;
    /* margin-top: 4rem; */
    margin-bottom: 160px;
    font-weight: 425;
    color: #FFAED8;
    padding: 0 28px 0 28px;
}

@media (min-width: 375px) {
    .my-who-card__text {
        font-size: 20px;
        margin-bottom: 148px;
        padding: 0 38px 0 38px;
    }
}

@media (min-width: 500px) {
    .my-who-card__text {
        padding: 0 38px 0 38px;
        width: 85%;
        margin-bottom: 120px;
    }
}

@media (min-width: 600px) {
    .my-who-card__text {
        margin-bottom: 200px;
    }
}

@media (min-width: 768px) {
    .my-who-card__text {
        font-size: 23px;
        width: 54vw;
        margin-left: 9.5vw;
        padding: 0;
        margin-bottom: 160px;
    }
}

@media (min-width: 1024px) {
    .my-who-card__text {
        font-size: 26px;
        margin-top: 2rem;
        width: 52vw;
    }
}

@media (min-width: 1200px) {
    .my-who-card__text {
        font-size: 28px;
        width: 50vw;
    }
}

@media (min-width: 1400px) {
    .my-who-card__text {
        font-size: 32px;
        width: 45vw;
    }
}

.my-marquee {
    position: relative;
    z-index: 3;
    width: 130%;
}

h2 {
    margin: 0;
    padding: 0;
}

.my-who-card__title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin: 40px 0;
    color: #FFAED8;
    letter-spacing: -0.04em;
    font-size: 20vw;
    letter-spacing: 0;
    font-weight: 450;
}

@media (min-width: 375px) {
    .my-who-card__title {
        margin: 48px 0;
        font-size: 20vw;
    }
}

@media (min-width: 768px) {
    .my-who-card__title {
        margin: 56px 0;
        font-size: 15vw;
    }
}

@media (min-width: 1024px) {
    .my-who-card__title {
        margin: 62px 0;
        font-size: 13vw;
    }
}

@media (min-width: 1200px) {
    .my-who-card__title {
        margin: 68px 0;
        font-size: 12vw;
    }
}

@media (min-width: 1400px) {
    .my-who-card__title {
        margin: 72px 0;
    }
}

.my-marquee-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin-top: -140px;
    /* height: 20vh!important; */
    /* overflow: hidden; */
}

@media (min-width: 600px) {
    .my-marquee-content {
        margin-top: -172px;
    }
}

@media (min-width: 1024px) {
    .my-marquee-content {
        margin-top: -115px;
    }
}

@media (min-width: 1400px) {
    .my-marquee-content {
        margin-top: -110px;
    }
}

.my-marquee-main {
    min-width: 180%;
    -webkit-transform: rotate(-24deg) translate(-350px, -25px);
    -moz-transform: rotate(-24deg) translate(-350px, -25px);
    -ms-transform: rotate(-24deg) translate(-350px, -25px);
    -o-transform: rotate(-24deg) translate(-350px, -25px);
    transform: rotate(-24deg) translate(-350px, -25px);
    overflow: hidden;
}

@media (min-width: 600px) {
    .my-marquee-main {
        min-width: 140%;
        -webkit-transform: rotate(-24deg) translate(-350px, -25px);
        -moz-transform: rotate(-24deg) translate(-350px, -25px);
        -ms-transform: rotate(-24deg) translate(-350px, -25px);
        -o-transform: rotate(-24deg) translate(-350px, -25px);
        transform: rotate(-24deg) translate(-350px, -25px);
    }
}

@media (min-width: 1024px) {
    .my-marquee-main {
        min-width: 120%;
        -webkit-transform: rotate(-24deg) translate(-100px, 0);
        -moz-transform: rotate(-24deg) translate(-100px, 0);
        -ms-transform: rotate(-24deg) translate(-100px, 0);
        -o-transform: rotate(-24deg) translate(-100px, 0);
        transform: rotate(-24deg) translate(-100px, 0);
    }
}

.swiper-pointer-events {
    touch-action: pan-y;
}

.my-marquee-carousel.-v1 {
    margin-right: -300px;
    margin-bottom: 12px;
    transition: 5ms;
    transform: translate3d(var(--marquee-move), 0px, 0px);
    will-change: transform;
}

@media (min-width: 768px) {
    .my-marquee-carousel.-v1 {
        margin-bottom: 16px;
    }
}

.my-marquee-carousel.-v2 {
    margin-left: -300px;
    margin-bottom: 12px;
    transform: translate3d(var(--marquee-move), 0px, 0px);
    will-change: transform;
}

.my-marquee-items {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    height: 165px !important;
    -webkit-transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
    -moz-transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
    -o-transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
}

@media (min-width: 768px) {
    .my-marquee-items {
        height: 250px !important;
    }
}

.swiper-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition-property: transform;
    box-sizing: content-box;
    transform: translate3d(0px, 0, 0);
}

.swiper-free-mode>.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    animation: marquee 9s linear infinite;
    /* transform: translate3d(var(--marquee-move), 0px, 0px); */
    will-change: transform;
}

.my-marquee-item {
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 165px !important;
    height: 100% !important;
    -webkit-box-shadow: 0 0 0 2px inset #FFAED8;
    -moz-box-shadow: 0 0 0 2px inset #FFAED8;
    box-shadow: 0 0 0 2px inset #FFAED8;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    border-radius: 500px;
    -webkit-transition: all 200ms ease !important;
    -o-transition: all 200ms ease !important;
    -moz-transition: all 200ms ease !important;
    transition: all 200ms ease !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

@media (min-width: 768px) {
    .my-marquee-item {
        width: 250px !important;
        margin-right: 16px;
    }
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}

.my-marquee-item__image {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(24deg);
    -moz-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    -o-transform: rotate(24deg);
    transform: rotate(24deg);
}

.my-marquee-item__text {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
}

img {
    border-style: none;
    vertical-align: middle;
}

.my-marquee-item img {
    width: 100%;
    z-index: 2;
}

p {
    margin: 0;
    padding: 0;
}

.my-marquee-item__title {
    color: #ffe6f4;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-bottom: -3px;
    text-shadow: 1px 1px #5a1143;
}

@media (min-width: 768px) {
    .my-marquee-item__title {
        margin-bottom: 2px;
        font-size: 15px;
    }
}

@media (min-width: 1400px) {
    .my-marquee-item__title {
        margin-bottom: 1px;
        font-size: 16px;
    }
}

.my-marquee-item__name {
    color: #ffe6f4;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
    text-shadow: 1px 1px #5a1143;
}

@media (min-width: 768px) {
    .my-marquee-item__name {
        font-size: 20px;
        margin-bottom: 22px;
    }
}

@media (min-width: 1400px) {
    .my-marquee-item__name {
        font-size: 24px;
        margin-bottom: 24px;
    }
}


.mark-img {
    border: 4px solid #FFAED8;
    margin: 1rem;
    border-radius: 50%;
}

.about-container {
    margin-top: 1rem;
}

.about-text-wrapper {
    position: absolute;
    top: 114px;
    /* left: -20%; */
    transform: translate(0, -50%);
    color: #FFAED8;
    font-size: 10rem;
    font-weight: 400;
    white-space: nowrap;
    transition: 0.5s !important;
    animation: float-text 20s linear infinite;
    animation-delay: 1s;
    padding: 20px 0;
}

@keyframes float-text {
    0% {
        left: -20%;
    }

    50% {
        left: -100%;
    }

    100% {
        left: -20%;
    }
}

.about-boxes {
    display: flex;
    /* color: red; */
    /* background-color: #fff; */
    padding: 3rem;
    width: 100%;
    transform: rotate(-14deg) !important;

}

.about-boxes div {
    padding: 5rem 6rem;
    margin-left: 6px;

    /* border:2px solid yellow ; */
    border-radius: 50%;
}


.about-container {
    position: relative;
    width: 100%;
    height: 200px;
}

.item {
    position: absolute;
    width: 50px;
    height: 50px;
    /* background: #4169e1; */
    animation: diagonal-move-anim 5s linear infinite alternate;
}

@keyframes diagonal-move-anim {

    0% {
        transform: translate(0px, 50px);
    }

    100% {
        transform: translate(600px, -300px);
    }

}

@media screen and (max-width:500px) {
    .about-container {
        display: none;
    }

    .about-text-wrapper {
        margin-top: 0rem;
        font-size: 5rem;
    }

    .my-who-card {

        margin-left: 5px !important;
    }

}


.js-scroll {
    opacity: 0;
    transition: opacity 500ms;
}

.js-scroll.scrolled {
    opacity: 1;
}

.scrolled.slide-left {
    transform: translate3d(-105.0px, 0px, 0px);

    /* transform: translateX(0); */
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-400px);
        transform: translateX(-400px);
        opacity: 0;

    }

    /* 50% {
        -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
            opacity: 1;
        
    } */

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    /* 200% {
        -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
            opacity: 0;
    }
    300% {
        -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
    } */

}

.scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}


.track .content {
    font-size: 12rem;
}

.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 300px;
    overflow-x: hidden;
}

.track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 9s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}


@media screen and (max-width:900px) {
    .marquee {
        margin-top: 2rem;
        position: relative;
        width: 100vw;
        max-width: 100%;
        height: 179px;
        overflow-x: hidden;
    }

    .track .content {
        font-size: 6rem;
    }
}