/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comme:wght@300;400;500&family=Lato:wght@100;300&family=Roboto:wght@300;400&display=swap'); */


:root {
  --bg: black;
  --primary-font: 600;
  --secondary-font: 400;
  --primary-size: 6rem;
  --secondary-size: 1.5em;
  --x: 0px 5px;
  --y: 3px 0px;
  --primary-style: 'Satoshi', sans-serif;
  ;
  --radius: 2rem;
  /* --marquee-move: 150px; */
}

body {
  /* height: 100vh; */
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  width: 99.4%;
  color: #fff;
  background-color: var(--bg);
  scroll-behavior: smooth !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: #fff;
}

h1 {
  font-family: var(--primary-style);
}