.nav-container {
    color: #fff;
    width: 100%;
    /* height: 60vh; */
    display: flex;
    flex-direction: row;
}

.nav-top {
    display: flex;
    flex-direction: row;
}

.nav-top>* {
    flex: 1 100%;
}

@media (max-width: 900px) {
    .nav-container {
        flex-direction: column;
    }

    .nav-top {
        flex-direction: column;
    }
}

/* cards basic styling */

.cards-container {
    position: relative;
    width: 25vw;
    height: 30vh;
    overflow: hidden;
    border-radius: 70px;
    transition: all 0.2s ease-out;
    transform: scale(0.98);
    transition: 0.7s;
}

.work .cards-container .cards,
.about .cards-container .cards,
.contacts .cards-container .cards {

    width: 100%;
    height: 100%;
    overflow: hidden;

}

.cards {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cards-container:hover {
    transform: scale(0.96);
    border-radius: 80px;
    cursor: pointer;

    .cards-icon {
        display: none;
    }
}

.cards-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 50px;
    font-size: 20px;
    z-index: 2;
}

.service-cards-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 50px;
    font-size: 20px;
    z-index: 2;
    border-radius: 50% !important;
}

.service-cards-icon img {

    border-radius: 50% !important;
}

.services .cards-container .cards .preview-text,
.work .cards-container .cards .preview-text,
.about .cards-container .cards .preview-text,
.contacts .cards-container .cards .preview-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 50px;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--primary-style);
}

.text-wrapper-track .content {
    /* font-size: 2rem; */
    font-family: var(--primary-style);
    font-weight: 600 !important;
}

.text-wrapper {
    opacity: 0;
    /* overflow-y: hidden; */
    position: relative;
    width: 100vw;
    margin-bottom: 1rem;
    max-width: 100%;
    height: 150px;
    /* overflow-x: hidden; */
}

.text-wrapper-track {

    position: absolute;
    /* top: 5%; */
    top: 42%;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 8s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

.cards-container:hover .text-wrapper {
    opacity: 1;
}

.services-track .content {
    font-size: 5rem;
    font-family: var(--primary-style);
    font-weight: var(--primary-font);
}

.services-marquee {
    top: 10rem;
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 120px;
    overflow-x: hidden;
}

.work .cards-container .card.services-marquee {
    top: 10rem;
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 120px;
    overflow-x: hidden;
}

.services-track {
    position: absolute;
    top: 0%;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 12s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

/* custom styling for nav items */

/* services */

.services .cards-container {
    background-color: #2900e8 !important;
    width: 50vw;
    height: 60vh;
}

.services .services-marquee {
    font-size: 70px;
    opacity: 1;
}

.services .cards-container:hover .services-marquee {
    opacity: 0;
}

.cards-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--primary-style);
}

.cards-logo {
    position: absolute;
    top: 0;
    right: 0;
    padding: 40px;
    /* font-size: 40px; */
    /* text-transform: uppercase; */
}

.cards-logo img {
    border-radius: 50%;
    /* font-size: 40px; */
    /* text-transform: uppercase; */
}

/* shapes container */

.shapes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shapes {
    width: 35vh;
    height: 35vh;
    animation: round_round 12s linear infinite;
}

.square {
    position: relative;
    top: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    background: #a5a1ff;
    animation: round_round 12s linear infinite;
    transition: 0.2s;
}


.circle {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    background: #a5a1ff;
    border-radius: 50%;
    animation: round_round 12s linear infinite;
    transition: 0.2s;
}

.triangle {
    position: relative;
    top: -10%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 100px solid #a5a1ff;
    animation: round_round 12s linear infinite;
    transition: 0.2s;
}

.services .cards-container:hover .square {
    background: #fff;
}

.cards-container:hover .circle {
    background: #ffc0cb;
}

.cards-container:hover .triangle {
    border-bottom: 100px solid #fff480;
}

@keyframes round_round {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes round_round {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* /* work  */

.work .cards-container {
    background: #fff;
}

.work .preview-text,
.work .cards-icon {
    color: #000;
}

.work .cards-container:hover .preview-text,
.work .cards-container:hover .cards-icon {
    transition: 0.2s;
    color: #fff;

}

/* /* about  */

.about .cards-container {
    background: #1b1b1f;
}

.about .cards-container:hover .preview-text,
.about .cards-container:hover .cards-icon {
    transition: 0.2s;
    color: #1b1b1f;
}

.about .cards-container .text-wrapper {
    font-size: 4rem !important;
}

/* /* contact  */

.contacts .cards-container {
    background: #fff480;
    width: 50vw;
    transform: scale(0.99, 0.93);
}

.contacts .cards-container:hover {
    transform: scale(0.97, 0.92);
}

.contacts .preview-text,
.contacts .cards-icon {
    color: #000;
}

.contacts .cards-container:hover .preview-text,
.contacts .cards-container:hover .cards-icon {
    transition: 0.2s;
    color: #fff480;
}

.contacts .cards-container:hover .text-wrapper {
    font-size: 4rem !important;
}

.contacts .cards-container:hover .text-wrapper {
    color: #000;
}

/* /* media queries  */

@media (max-width: 900px) {
    .cards-title {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 20px;
        padding-top: 3rem;
        font-size: 17px;
        font-weight: 700;
    }

    .services .services-marquee {
        margin-top: 3rem;
    }

    .cards-logo {
        position: absolute;
        top: 9px;
        right: -25px;
        padding-left: 20px;
        padding-top: 2rem;
    }

    .cards-logo img {
        border-radius: 50%;
    }

    .work .cards-container .cards,
    .about .cards-container .cards,
    .contacts .cards-container .cards {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }

    .work .cards-container .cards .preview-text,
    .about .cards-container .cards .preview-text,
    .contacts .cards-container .cards .preview-text {
        padding: 0;
        position: static;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .cards-icon {
        position: static;
        padding: 0px;
        /* display: none; */
    }

    /* .nav-container {
        height: 117vh;
    } */

    .cards-container {
        width: 100%;
        border-radius: 8rem;
    }

    .nav-top {
        flex-direction: row;
    }

    .contacts .cards-container {
        width: 100%;
        border-radius: 5rem;
    }

    .services .cards-container {
        width: 100%;
        height: 65vh;
        border-radius: 3rem;
    }

    .services .text-wrapper {
        font-size: 40px;
    }

    .text-wrapper {
        display: none;
    }



    .services-track {
        position: absolute;
        top: 0%;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 7s linear infinite;
    }

    .square {
        position: relative;
        top: 0;
        left: 50%;
        width: 77px;
        height: 77px;
        background: #a5a1ff;
        animation: round_round 12s linear infinite;
        transition: 0.2s;
    }

    .circle {
        position: relative;
        left: 0;
        bottom: 0;
        width: 77px;
        height: 77px;
        background: #a5a1ff;
        border-radius: 50%;
        transition: 0.2s;
    }

    .triangle {
        position: relative;
        top: -10%;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 70px solid #a5a1ff;
        animation: round_round 12s linear infinite;
        transition: 0.2s;
    }

    .shapes {
        width: 25vh;
        height: 25vh;
        animation: round_round 12s linear infinite;
    }
}

@media screen and (max-width:500px) {

    .nav-top {
        /* justify-content: center; */
        flex-direction: row;
        margin-left: 7px;
    }

    .nav-buttom {

        margin-left: 5px;
    }

    .services .cards-container {
        width: 100%;
        height: 60vh;
        margin-left: 5px;
    }

    .preview-text {
        left: 5px;
    }

    .work {

        width: 100%;
    }

    .work .cards-container .cards,
    .about .cards-container .cards,
    .contacts .cards-container .cards {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }

    .work .cards-container .cards .preview-text,
    .about .cards-container .cards .preview-text,
    .contacts .cards-container .cards .preview-text {
        padding: 0;
        position: static;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    /* .about .cards-container .cards .preview-text {
        padding: 0;
        position: static;
        font-size: 20px;
        font-weight: 500;
    } */

    .cards-container {
        /* position: relative; */
        /* width: 25vw; */
        height: 28vh;
        /* overflow: hidden;
    border-radius: 70px;
    transition: all 0.2s ease-out;
    transform: scale(0.98);
    transition: 0.7s; */
    }

    .cards-icon {
        font-size: 20px;
        z-index: 2;
    }

    .services-marquee {
        top: 14rem;
    }

    .services-wrapper-track {
        position: absolute;
        top: 0%;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 7s linear infinite;
    }

    .services-track .content {
        font-size: 3rem;
        font-family: var(--primary-style);
        font-weight: 600 !important;
    }

    .square {
        animation: square_round_round 7s linear infinite;
    }

    .circle {
        animation: circle_round_round 7s linear infinite;
    }

    .triangle {
        animation: triangle_round_round 7s linear infinite;

    }

    @keyframes square_round_round {
        from {
            transform: rotate(0deg);
            background-color: #959EE5;
        }

        to {
            transform: rotate(360deg);
            background-color: #FFFFFF;
        }
    }

    @keyframes circle_round_round {
        from {
            transform: rotate(0deg);
            background-color: #959EE5;
        }

        to {
            transform: rotate(360deg);
            background-color: #ffc0cb;
        }
    }

    @keyframes triangle_round_round {
        from {
            transform: rotate(0deg);
            border-bottom: 100px solid #959EE5;
        }

        to {
            transform: rotate(360deg);
            border-bottom: 100px solid #fff480;
        }
    }

}