.scroll-up-button {
    position: fixed;
    height: 45px;
    width: 45px;
    text-align: center;
    background-color: rgb(206, 175, 3);
    background-color: var(--primary);
    color: white;
    color: rgba(94, 94, 94, 0.679);
    bottom: 30px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 6px;
    line-height: 45px;
    z-index: 9999;
    opacity: 0;
    animation: up 3s;
    animation-iteration-count: infinite;
    padding-left: 10px;
}


@keyframes up {
    0% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0px);
    }
}

.scroll-up-button.show {
    opacity: 1;
}

.scroll-up-button Hashlink {
    text-decoration: none;
    color: white;
    color: rgba(94, 94, 94, 0.679);
    transition: 4s;
}
