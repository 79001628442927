.vertical-timeline-element-title {
  color: #A5A1FF !important;
  padding-top: 0.25em;
  font-size: 4em !important;
  font-family: var(--primary-style);
  font-weight: 600;
}

#description {
  margin: 2em 0 2em 0;
  color: #b4b1b1;
  font-weight: 400;
  font-size: 19px;
  font-family: var(--primary-style);
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  width: 60px;
  height: 60px;
  left: 50.5% !important;
  margin-left: -30px;
}

.vertical-timeline-element-content {
  background-color: #1B1B1F !important;
  border-radius: 2rem !important;
  border: 2px solid #A5A1FF !important;
  padding: 1em 2em !important;
  transition: 0.7s !important;
}

.vertical-timeline-element-content:hover {
  transform: scale(0.97) !important;
}

.vertical-timeline-element-content h2 {
  color: #303e49;
  font-size: 2rem !important;
}

.vertical-timeline:before {
  background: linear-gradient(20deg, #FFAED8, #FFFFFF, #1F1A1C, #A5A1FF) !important;
  animation: gradient 15s ease infinite !important;
  width: 16px !important;
  border-radius: 1rem 1rem 0rem 0rem !important;
}

@media only screen and (max-width: 1170px) {
  .vertical-timeline-element-date {
    display: block;
    float: none;
    color: rgb(86, 85, 85);
    margin-top: 1.5em;
  }
}

@media only screen and (max-width: 600px) {
  .vertical-timeline-element-title {
    padding-top: 0.25em;
    font-size: 1em !important;
  }

  .proccess-headinglg {
    font-size: 3rem !important;
    display: none !important;
  }

  .proccess-heading {
    font-size: 3rem !important;
    /* display: none !important; */
  }

  #description {
    margin: 2em 0 2em 0;
    color: #b4b1b1;
    font-weight: 400;
    font-size: 19px;
  }

  .vertical-timeline-element-content {
    margin-left: 1px !important;
  }

  .vertical-timeline-element-content-arrow {
    border-right-color: transparent !important;
  }

  .vertical-timeline-element-content {
    padding: 1em 1em !important;
    transition: 3s !important;
  }

  .vertical-timeline:before {
    background: linear-gradient(20deg, #FFAED8, #FFFFFF, #1F1A1C, #A5A1FF) !important;
    animation: gradient 15s ease infinite !important;
    width: 16px !important;
    left: 213px !important;
    border-radius: 1rem 1rem 0rem 0rem !important;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .vertical-timeline-element-icon {
    display: none !important;

  }
}