.custom-button-li {
    list-style: none;
    --c: #FFF480;
    color: var(--c);
    border: 2px solid var(--c);
    font-size: 20px;
    border-radius: 3rem;
    font-weight: 600;
    /* font-family: sans-serif; */
    letter-spacing: 0.1em;
    text-align: center;
    line-height: 1em;
    position: relative;
    overflow: hidden;
    padding: 1.5rem 3rem;
    z-index: 1;
    transition: 0.5s;
}

.custom-button-li span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: var(--c);
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
}

.custom-button-li:hover {
    color: #0f172a;
}

.custom-button-li:hover span {
    transform: translateY(0) scale(2);
}

.custom-button-li span:nth-child(1) {
    --n: 1;
}

.custom-button-li span:nth-child(2) {
    --n: 2;
}

.custom-button-li span:nth-child(3) {
    --n: 3;
}

.custom-button-li span:nth-child(4) {
    --n: 4;
}

@media screen and (max-width:900px) {
    .custom-button-li {
        /* font-size: 14px!important; */
        padding: 1rem 0.2rem;
        display: block;
        /* font-size: 1px; */
        font-weight: 550;
        width: 100%;
    }

    .btn {
        padding: 0.2rem 0px !important;
    }
}