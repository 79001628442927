.ProjectsSection {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
}

/* .magin-x{
    margin-right: 0.3rem!important;
    margin-left: 0.3rem!important;
} */
.boxes {
    padding: 4rem 3rem;
    width: 100%;
    margin: auto;
    /* height: 65vh; */
    margin-top: 5px;
    margin-bottom: 5px;
    /* margin: 5px -9px !important; */
    border-radius: 4rem;
    transition: 0.7s;
}

.boxes:hover {
    transform: scale(0.97);

}

.box-1 {

    background-color: #C69CFD;
}

.box-2 {
    margin-left: 4px !important;
    background-color: #CBE4FF;
}

.box-3 {

    /* background-color: #FFEEB6; */
    /* height: 54.8vh; */
    margin-left: -4px !important;
    background-color: #FFEEB6;
}

.box-4 {

    margin-left: 4px !important;
    background-color: #021F22;
}

.box-5 {

    margin-left: -4px !important;
    background-color: #280138;
}

.logo-div {
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-div img {
    width: auto;
    max-height: 100%;
}

.top-div,
.lower-div {
    display: flex;
    justify-content: space-between;
}

.col-6 {
    padding-right: 0px;
}

.funFlixImg {
    width: 250px !important;
}

.homeServiseImg {
    width: 250px !important;
}

@media screen and (max-width:500px) {
    .ProjectsSection {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .row {
        padding-left: 0.2rem !important;
    }

    .box-2,
    .box-3,
    .box-4,
    .box-5 {
        /* background-color: #CBE4FF; */
        width: 100%;
        height: auto;
        border-radius: 6rem !important;
    }

    .box-3,
    .box-5 {
        margin-left: -4px !important;
    }

    .boxes {
        padding: 3rem 1rem;
        /* height: 55vh; */
        margin-top: 5px;
        margin-bottom: 5px;
        /* margin: 5px -9px !important; */
        border-radius: 3.5rem;
        transition: 0.7s;
    }

    .top-div {
        justify-content: center !important;
    }

    .logo-div {
        height: auto;
        width: 100%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

    .workLogoDiv {
        /* width: 150px !important; */
        width: 100% !important;
        height: auto !important;
    }

    .homeServiseImg,
    .unicorn,
    .fingerPrint {
        /* width: 150px !important; */
        width: 100% !important;
        height: auto !important;
    }

    .lower-div {
        display: none;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .lower-div {
        /* display: none !important; */
        justify-content: center;
    }

    .up {
        margin-bottom: 1rem;
        justify-content: space-between !important;
    }

    .down {
        margin-top: 2rem !important;
        justify-content: space-between !important;
    }
}