.contactSection {
    margin: 0.5rem;
    width: 100%;
}

.contact {
    /* background-color: #1D1C16; */
    padding: 0rem 1rem;

}

.contact-heading-div {
    background-color: #1D1C16;
    margin-top: 0.5rem;
    margin: var(--x);
    padding: 3rem 0rem;
    border-radius: 4rem;
}

.contact-heading-button-top {
    margin: 2rem 4rem;
    display: flex;
    align-items: center;

}

.contact-heading-button-lower {
    margin: 0rem 4rem;
    padding-bottom: 3rem;
    margin: 2rem 4rem;
    display: flex;
    align-items: center;

}

.btn-div {
    margin: 0 6px;
    margin-top: 3rem;
    background-color: none;
    padding: 0 !important;
    border-radius: 3rem;

}

input,
textarea {
    width: 100%;
    background-color: #1D1C16;
    color: #FFF480 !important;
    outline: none;
    border: none;
    padding: 2.6rem 4rem;
    border: 2px solid transparent;
    border-radius: 4rem;
    transition: 0.7s;
    font-family: var(--primary-style);
    /* font-weight:00 !important; */
}

.contact-row>* {

    /* padding-right: 0 !important; */
    padding-left: 5px !important;

}

input::placeholder,
textarea::placeholder {

    color: #FFF480 !important;
    font-weight: 400;
}

input:hover {
    border: 2px solid #FFF480;
}

.messege {
    height: 50vh;
}

.send {
    display: flex;
    /* color: #fff; */
    color: #1D1C16;
    font-weight: var(--primary-font);
    align-items: center;
    /* margin-top: 11px; */
    /* height: 71vh; */
    justify-content: center;
    padding: 4rem 2rem;
    background-color: #FFF480;
    border-radius: 4rem;
    transition: all 0.3s ease-out;
    transform: scale(0.98);
}

.send:hover {
    transform: scale(0.95);
    border-radius: 80px;
    cursor: pointer;
}

.con-heading {
    /* margin-top: 0.5rem; */
    margin: 0.5rem;
    padding: 3rem 4rem;
    border-radius: 4rem !important;
    width: 99%;

}

.con-heading p {

    font-weight: var(--primary-font);
    font-family: var(--primary-style);
    letter-spacing: 0.1rem;
    font-size: 1rem;
    text-align: left !important;
}

.con-heading h1 {
    margin-top: 3rem;
    font-family: var(--primary-style);
    font-weight: 575;
    /* color: black; */
    font-size: var(--primary-size);
    text-align: left;
    width: 100%;
}

@media screen and (max-width:900px) {
    .contact {
        /* background-color: #1D1C16; */
        margin-right: 3px;
        padding: 0rem 0rem;
    }

    .contact-heading-div {
        width: 100%;
        background-color: #1D1C16;
        /* margin-top: 0.5rem; */
        /* margin: 5px; */
        padding: 3rem 1rem;
        border-radius: 4rem;
    }

    .contact-heading-button-top {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
    }

    .contact-heading-button-lower {
        margin: 0rem 0rem;
        padding-bottom: 3rem;

    }

    .send {
        /* display: flex; */
        /* color: #fff; */
        color: #1D1C16;
        font-weight: var(--primary-font);
        align-items: center;
        /* margin-top: 0px; */
        /* height: 71vh; */
        justify-content: center;
        padding: 3rem 0rem;
        background-color: #FFF480;
        border-radius: 4rem;
        /* margin-left: 6px; */
    }

    .btn {
        width: 100%;
        padding: 0.2rem 0px !important;
    }

    input,
    textarea {

        width: 100%;
        background-color: #1D1C16;
        color: #FFF480 !important;
        outline: none;
        border: none;
        padding: 2rem 2rem;
        border: 2px solid transparent;
        border-radius: 4rem;
        transition: 0.7s;
    }
}

@media screen and (max-width: 500px) {
    .con-heading h1 {
        margin-top: 3rem;
        /* color: black; */
        font-size: 2rem;
        text-align: left;
        width: 100%;
    }

    .con-heading {
        padding: 0rem 1.5rem !important;
        margin: 3px;
    }


}