.card {
    /* margin: 0remrem !important; */
    border-radius: 1rem;
    height: auto !important;
    width: 95% !important;


}

.width-card-offer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
}


.offer-card-button {
    /* margin-top:rem !important; */
    width: 94%;
    margin-left: -1rem;
}


@media screen and (max-width:1400px) {
    .card {
        /* height: 412px !important;
        width: 300px !important; */
        height: auto !important;
        width: 100% !important;
    }

    .card img {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width:900px) {
    .card {
        /* height: 450px !important;
        width: 320px !important; */
        height: auto !important;
        width: 100% !important;
    }

    .card img {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width:800px) {
    .card {
        height: auto !important;
        width: 100% !important;
    }

    .card img {
        width: 100%;
        height: auto;
    }

    .offer-card-button {
        /* margin-top:rem !important; */
        width: 94%;
        margin-left: 0rem;
    }
}