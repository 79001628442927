/* @import url(http://fonts.googleapis.com/css?family=Ruslan + Display&subset=latin,cyrillic); */

/* body {
    text-align: center;
    margin: 0;
    padding: 0;
} */

/*   virus window  */
.center {
    width: 503px;
    min-width: 500px;
    height: 30vh;
    min-height: 30vh;
    /* background: #0c0404; */
    border: -3px solid #848484;
    /* border-top: 1px solid #20202E; */
    /* border-left: 1px solid #20202E; */
    box-shadow: 2px;
    margin: 0 auto;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    text-align: center;
    position: relative;
    top: 255px;
    cursor: pointer;
}

.header {
    /* height: 29px; */
    vertical-align: middle;
    /* padding-left: 3px; */
    /* background: #000084; */
    font-family: Arial;
    color: white;
    font-size: 1.8em;
    text-align: center;
}

.brand-name-loader {
    font-size: var(--secondary-size);
    /* color: #00137f; */
    margin-top: 30px;
}

.center p {
    font-family: Arial;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 4px;
    margin-left: 16px;
    margin-bottom: 34px;
}

.progressbar {
    width: 98%;
    height: 24px;
    margin: auto;
    background: #f0f0f0;
    border-radius: 3rem;
    border: 1px solid #afafaf;
    text-align: left;
    padding: 0px;
}


.progressbar div {
    height: 100%;
    border-radius: 3rem;
    -webkit-animation: move 2.5s linear;
    -moz-animation: move 2.5s linear;
    -o-animation: move 2.5s linear;
    animation: move 2s linear;
    background: #000084;
}

@-o-keyframes move {
    0% {
        width: 0%;
    }

    5% {
        width: 5%;
    }

    10% {
        width: 10%;
    }

    15% {
        width: 15%;
    }

    20% {
        width: 20%;
    }

    25% {
        width: 25%;
    }

    30% {
        width: 30%;
    }

    35% {
        width: 35%;
    }

    40% {
        width: 40%;
    }

    45% {
        width: 45%;
    }

    50% {
        width: 50%;
    }

    55% {
        width: 55%;
    }

    60% {
        width: 60%;
    }

    65% {
        width: 65%;
    }

    70% {
        width: 70%;
    }

    75% {
        width: 75%;
    }

    80% {
        width: 80%;
    }

    85% {
        width: 85%;
    }

    90% {
        width: 90%;
    }

    95% {
        width: 95%;
    }

    100% {
        width: 100%;
    }
}

@-moz-keyframes move {
    0% {
        width: 0%;
    }

    5% {
        width: 5%;
    }

    10% {
        width: 10%;
    }

    15% {
        width: 15%;
    }

    20% {
        width: 20%;
    }

    25% {
        width: 25%;
    }

    30% {
        width: 30%;
    }

    35% {
        width: 35%;
    }

    40% {
        width: 40%;
    }

    45% {
        width: 45%;
    }

    50% {
        width: 50%;
    }

    55% {
        width: 55%;
    }

    60% {
        width: 60%;
    }

    65% {
        width: 65%;
    }

    70% {
        width: 70%;
    }

    75% {
        width: 75%;
    }

    80% {
        width: 80%;
    }

    85% {
        width: 85%;
    }

    90% {
        width: 90%;
    }

    95% {
        width: 95%;
    }

    100% {
        width: 100%;
    }
}

@-webkit-keyframes move {
    0% {
        width: 0%;
    }

    5% {
        width: 5%;
    }

    10% {
        width: 10%;
    }

    15% {
        width: 15%;
    }

    20% {
        width: 20%;
    }

    25% {
        width: 25%;
    }

    30% {
        width: 30%;
    }

    35% {
        width: 35%;
    }

    40% {
        width: 40%;
    }

    45% {
        width: 45%;
    }

    50% {
        width: 50%;
    }

    55% {
        width: 55%;
    }

    60% {
        width: 60%;
    }

    65% {
        width: 65%;
    }

    70% {
        width: 70%;
    }

    75% {
        width: 75%;
    }

    80% {
        width: 80%;
    }

    85% {
        width: 85%;
    }

    90% {
        width: 90%;
    }

    95% {
        width: 95%;
    }

    100% {
        width: 100%;
    }
}

@keyframes move {
    0% {
        width: 0%;
    }

    5% {
        width: 5%;
    }

    10% {
        width: 10%;
    }

    15% {
        width: 15%;
    }

    20% {
        width: 20%;
    }

    25% {
        width: 25%;
    }

    30% {
        width: 30%;
    }

    35% {
        width: 35%;
    }

    40% {
        width: 40%;
    }

    45% {
        width: 45%;
    }

    50% {
        width: 50%;
    }

    55% {
        width: 55%;
    }

    60% {
        width: 60%;
    }

    65% {
        width: 65%;
    }

    70% {
        width: 70%;
    }

    75% {
        width: 75%;
    }

    80% {
        width: 80%;
    }

    85% {
        width: 85%;
    }

    90% {
        width: 90%;
    }

    95% {
        width: 95%;
    }

    100% {
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .center {
        width: 437px;
        min-width: 437px;
        height: 30vh;
        min-height: 30vh;
        /* background: #0c0404; */
        border: -3px solid #848484;
        /* border-top: 1px solid #20202E; */
        /* border-left: 1px solid #20202E; */
        box-shadow: 2px;
        margin: 0 auto;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 6px;
        text-align: center;
        position: relative;
        top: 255px;
        cursor: pointer;
    }
}

/* .center button {
    margin: 0px;
}

/*    contacts window 

#contacts {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00067c;
    opacity: 0.8;
    z-index: 5;
}

#contacts:target {
    display: block;
}

#contacts>div {
    width: 700px;
    height: 300px;
    margin: 10% auto;
    position: relative;
    background: #fff;
    z-index: 10;
    text-align: center;
    padding: 10px;
}

#contacts>div>a {
    font-size: 20px;
    font-weight: bold;
    color: #00137f;
    text-decoration: none;
    display: block;
    padding: 5px;
    width: 20px;
    height: 20px;
    border: 1px solid #00137f;
    border-radius: 25px;
    margin: 10px;
    float: right;
    vertical-align: middle;
} */

button {
    cursor: pointer;
}